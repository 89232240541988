import React, { useState, useEffect } from 'react';
import { Modal, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import TextEditor from "../../inventory/texteditor";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import purchaseInvoiceActions from '../../../core/Services/purchases/purchaseInvoice/actions';
import purchaseReceiveActions from '../../../core/Services/purchases/purchaseReceive/actions';
import commonAction from "../../../core/Services/common/actions";
import * as purchasesSelector from '../purchasesSelector';
import * as commonSelector from '../../commonSelectors';

const AddPurchaseInvoiceModal = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, purchaseInvoiceParams } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [selectedBillDate, setSelectedBillDate] = useState(new Date);
  const [selectedBillDueDate, setSelectedBillDueDate] = useState(new Date);
  const [editorData, setEditorData] = useState();

  const allPurchaseReceiveData = useSelector(state => commonSelector.setAllPurchaseReceiveDrp(state));
  const allPurchaseReceive = allPurchaseReceiveData ? allPurchaseReceiveData.toJS() : [];
  // console.log("all purchase receives",allPurchaseReceive);
  const purchaseReceiveData = useSelector(state => purchasesSelector.setPurchaseReceiveById(state));
  const purchaseReceiveDataSource = purchaseReceiveData ? purchaseReceiveData.toJS() : [];
  let purchaseReceive = purchaseReceiveDataSource ? purchaseReceiveDataSource.data : [];
  // console.log("purchase receives",purchaseReceive);

  useEffect(() => {
    dispatch(commonAction.fetchAllPurchaseReceiveDrp());
    dispatch(commonAction.getAllSuppliers());
  }, [])

  useEffect(() => {
    if (isUpdate && currentModalData) {
      let editorFetchedData = currentModalData.formData?.notesHtml.replace(/""/g, '');
      setEditorData(editorFetchedData);
    }
  }, [isUpdate, currentModalData]);

  const handleBillDateChange = (date) => {
    setSelectedBillDate(date);
    form.setFieldsValue({ billdate: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  const handleBillDueDateChange = (date) => {
    setSelectedBillDueDate(date);
    form.setFieldsValue({ billduedate: date ? moment(date).format('YYYY-MM-DD') : null });
  };


  const handleRecord = (actionName, purchaseInvoiceData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      purchaseInvoiceData.id = currentModalData.formData?.id;
      purchaseInvoiceData.companyId = currentModalData.formData?.companyId;
      purchaseInvoiceData.purchaseRecId = currentModalData.formData?.purchaseRecId;
      purchaseInvoiceData.notesHtml = editorData;
    } else {
      purchaseInvoiceData.companyId = 1;
      purchaseInvoiceData.purchaseRecId = purchaseReceive.id;
      purchaseInvoiceData.notesHtml = editorData;
    }
    dispatch(purchaseInvoiceActions.addUpdatePurchaseInvoice({ purchaseInvoiceData, actionName, purchaseInvoiceParams: purchaseInvoiceParams }));
    toggleModal();
  };

  const handleChangePurchaseReceive = (e) => {
    dispatch(purchaseReceiveActions.getPurchaseReceiveById({ purchaseReceiveParam: e }));
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    let stringifyData = `${data}`
    setEditorData(stringifyData);
  };

  return (
    <Modal
      open={isModalOpen}
      width={1140}
      zIndex={99999}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="purchases.purchaseinvoice.form.modal.title.update" />
        ) : (
          <IntlMessages id="purchases.purchaseinvoice.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          purchaseRecId: currentModalData.formData?.purchaseRecId,
          supplierdono: currentModalData.formData?.supplierDONo,
          supplierinvoiceno: currentModalData.formData?.supplierInvoiceNo,
          billdate: currentModalData.formData?.billDate ? moment(currentModalData.formData.billDate).format('YYYY-MM-DD') : null,
          billduedate: currentModalData.formData?.billDate ? moment(currentModalData.formData.billDueDate).format('YYYY-MM-DD') : null,
          notesHtml: editorData,
        }}
      >
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks add-product">
              <div className="row">
                <div className="col-lg-10 col-sm-10 col-10">
                  <Form.Item
                    name="purchaseRecId"
                    className="actions_addModalFormItem"
                    label={
                      <CustomFormLabel
                        labelkey="purchases.invoice.form.grn"
                        popoverkey="purchases.invoice.form.popover.grn"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="purchase.invoice.modal.validation.grn" />,
                      },
                    ]}
                  >

                    <Select
                      options={allPurchaseReceive.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder="Choose"
                      onChange={handleChangePurchaseReceive}
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      notFoundContent="No results found"
                    />
                  </Form.Item>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks add-product">
              <div className="row">
                <div className="col-lg-10 col-sm-10 col-10">
                  <Form.Item
                    name="supplierdono"
                    className="actions_addModalFormItem"
                    label={
                      <CustomFormLabel
                        labelkey="purchases.invoice.form.supplierDo"
                        popoverkey="purchases.invoice.form.popover.supplierDo"
                      />
                    }
                  >
                    <input type='text'></input>
                  </Form.Item>
                </div>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks add-product">
              <div className="row">
                <div className="col-lg-10 col-sm-10 col-10">
                  <Form.Item
                    name="supplierinvoiceno"
                    className="actions_addModalFormItem"
                    label={
                      <CustomFormLabel
                        labelkey="purchases.invoice.form.supplierInvoice"
                        popoverkey="purchases.invoice.form.popover.supplierInvoice"
                      />
                    }
                  >
                    <input type='text'></input>
                  </Form.Item>
                </div>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <Form.Item
                name="billdate"
                className="actions_addModalFormItem"
                label={
                  <CustomFormLabel
                    labelkey="purchases.invoice.form.billDate"
                    popoverkey="purchases.invoice.form.popover.billDate"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="purchase.invoice.modal.validation.billdate" />,
                  },
                ]}
              >
                <DatePicker
                  selected={selectedBillDate}
                  onChange={handleBillDateChange}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select Date"
                  className="actions_addModalFormItem"
                />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="input-blocks">
              <Form.Item
                name="billduedate"
                className="actions_addModalFormItem"
                label={
                  <CustomFormLabel
                    labelkey="purchases.invoice.form.billDueDate"
                    popoverkey="purchases.invoice.form.popover.billDate"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="purchase.invoice.modal.validation.billduedate" />,
                  },
                ]}
              >
                <DatePicker
                  selected={selectedBillDueDate}
                  onChange={handleBillDueDateChange}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select Date"
                  className="actions_addModalFormItem"
                />
              </Form.Item>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="modal-body-table">
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      <th><IntlMessages id="purchase.invoice.modal.table.product" /></th>
                      <th><IntlMessages id="purchase.invoice.modal.table.qty" /></th>
                      <th><IntlMessages id="purchase.invoice.modal.table.discount" /></th>
                      <th><IntlMessages id="purchase.invoice.modal.table.tax" /></th>
                      <th><IntlMessages id="purchase.invoice.modal.table.taxAmount" /></th>
                      <th><IntlMessages id="purchase.invoice.modal.table.unitPrice" /></th>
                      <th><IntlMessages id="purchase.invoice.modal.table.totalCost" /></th>

                    </tr>
                  </thead>
                  <tbody>
                    {isUpdate ? currentModalData.formData?.purchaseRecievedDetails?.map((item, index) => (
                      <tr key={index}>
                        <td className="">{item.productName}</td>
                        <td className="">{item.quantity}</td>
                        <td className="">{item.disscount}</td>
                        <td className="">{item.taxPercentage}</td>
                        <td className="">{item.taxAmount}</td>
                        <td className="">{item.unitCost}</td>
                        <td className="">{item.totalCost}</td>
                      </tr>
                    )) : purchaseReceive?.recievedDetails?.map((item, index) => (
                      <tr key={index}>
                        <td className="">{item.productName}</td>
                        <td className="">{item.quantity}</td>
                        <td className="">{item.disscount}</td>
                        <td className="">{item.taxPercentage}</td>
                        <td className="">{item.taxAmount}</td>
                        <td className="">{item.unitPrice}</td>
                        <td className="">{item.totalCost}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>

        <div className="col-lg-12">
          <div className="input-blocks summer-description-box">
            <Form.Item
              name="notesHtml"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="purchases.purchase.form.purchase.label.notes"
                  popoverkey="purchase.purchase.form.label.popover.purchase.note"
                />
              }
            >
              <TextEditor data={editorData} onChange={handleEditorChange} />
            </Form.Item>

          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddPurchaseInvoiceModal;
