import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs } from 'antd';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import { PlusCircle } from "react-feather";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Flex, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'react-bootstrap';
import commonActions from '../../../../core/Services/common/actions'
import * as commonSelectors from '../../../commonSelectors'

const AllowanceInformationTab = (props) => {
    const { form, currentModalData, handleCustomFieldsData, isUpdate, employeeOptionsData } = props;
    const dispatch = useDispatch();

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    hraAllowance: currentModalData?.formData?.hraAllowance,
                    conveyanceAllowance: currentModalData?.formData.conveyanceAllowance,
                    medicalAllowance: currentModalData?.formData?.medicalAllowance,
                    bonusAllowance: currentModalData?.formData?.bonusAllowance,
                }}
                style={{ marginTop: '20px' }}
            >

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="hraAllowance"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="hrm.payroll.form.hra"
                                                popoverkey="HRA Allowance"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "HRA Allowance Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "HRA Allowance must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add HRA Allowance'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="conveyanceAllowance"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="hrm.payroll.form.conveyance"
                                                popoverkey="Conveyance Allowance"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Conveyance Allowance Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Conveyance Allowance must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add Conveyance Allowance'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="medicalAllowance"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="hrm.payroll.form.medical"
                                                popoverkey="Medical Allowance"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Medical Allowance Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Medical Allowance must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add Medical Allowance'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="bonusAllowance"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="hrm.payroll.form.bonus"
                                                popoverkey="Bonus"
                                            />
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Bonus Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Bonus must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Add Bonus'/>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AllowanceInformationTab;
