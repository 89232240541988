import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs } from 'antd';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import { PlusCircle } from "react-feather";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Flex, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'react-bootstrap';
import commonActions from '../../../../core/Services/common/actions'
import * as commonSelectors from '../../../commonSelectors'

const SalarySummaryInformationTab = (props) => {
    const { form, currentModalData, handleCustomFieldsData, isUpdate, employeeOptionsData } = props;
    const dispatch = useDispatch();

    const getTotalAllowance = () => {
        if (isUpdate && currentModalData && currentModalData.formData) {
            return currentModalData.formData.basicSalary + currentModalData.formData.hraAllowance + currentModalData.formData.conveyanceAllowance + currentModalData.formData.medicalAllowance + currentModalData.formData.bonusAllowance
        }
    }

    const getTotalAllowanceFromFields = () => {
        return Number.parseInt(form.getFieldValue('basicSalary')) + Number.parseInt(form.getFieldValue('hraAllowance')) + Number.parseInt(form.getFieldValue('conveyanceAllowance')) + Number.parseInt(form.getFieldValue('medicalAllowance')) + Number.parseInt(form.getFieldValue('bonusAllowance'))
    }

    const getTotalDeductions = () => {
        if (isUpdate && currentModalData && currentModalData.formData) {
            return currentModalData.formData.pfDeduction + currentModalData.formData.professionalTax + currentModalData.formData.tds + currentModalData.formData.loans
        }
    }

    const getTotalDeductionsFromFields = () => {
        return Number.parseInt(form.getFieldValue('pfDeduction')) + Number.parseInt(form.getFieldValue('professionalTax')) + Number.parseInt(form.getFieldValue('tds')) + Number.parseInt(form.getFieldValue('loans'))
    }

    useEffect(() => {
        if (isUpdate) {
            form.setFieldsValue({ totalAllowance: (getTotalAllowance()).toLocaleString() })
            form.setFieldsValue({ totalDeduction: (getTotalDeductions()).toLocaleString() })
            form.setFieldsValue({ netSalary: (getTotalAllowance() - getTotalDeductions()).toLocaleString() })
        }
        else
        {
            form.setFieldsValue({ totalAllowance: (getTotalAllowanceFromFields()).toLocaleString() })
            form.setFieldsValue({ totalDeduction: (getTotalDeductionsFromFields()).toLocaleString() })
            form.setFieldsValue({ netSalary: (getTotalAllowanceFromFields() - getTotalDeductionsFromFields()).toLocaleString() })
        }
    }, [])

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '20px' }}
            >
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="totalAllowance"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="hrm.payroll.form.totalAllowance"
                                                popoverkey="Total Allowance"
                                            />
                                        }
                                    >
                                        <input type='text' disabled />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                            <div className="row">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="totalDeduction"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="hrm.payroll.form.totalDeduction"
                                                popoverkey="Total Deduction"
                                            />
                                        }
                                    >
                                        <input type='text' disabled />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="">
                                    <Form.Item
                                        name="netSalary"
                                        className="actions_addModalFormItem"
                                        label={
                                            <CustomFormLabel
                                                labelkey="hrm.payroll.form.netSalary"
                                                popoverkey="Net Salary"
                                            />
                                        }
                                    >
                                        <input type='text' disabled />
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default SalarySummaryInformationTab;
