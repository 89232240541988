import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import Table from "../../../core/pagination/datatable";
import AddQuotationModal from "./addQuotation";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import Swal from "sweetalert2";
import {
  Filter,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { setToogleHeader } from "../../../core/redux/action";
import quotationAction from "../../../core/Services/purchases/quotations/actions.js"
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import * as purchasesSelectors from '../purchasesSelector.js';
import { filterDate } from "../../../core/Common/Constant_Variables/common"
import * as ApiResponseSelectors from '../../selectors';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const PurchasesQuotationsList = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [quotationParams, setquotationParams] = useState({
    pageSize: 10,
    pageNumber: 1,
    searchText: '',
  });

  const [isQuotationModalOpen, setQuotationModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(quotationParams.searchText, 1000);

  const isLoading = useSelector(state => purchasesSelectors.quotationLoader(state));
  const QuotationData = useSelector(state => purchasesSelectors.setQuotations(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const tableData = QuotationData ? QuotationData.toJS() : [];
  const dataSource = tableData ? tableData.data : [];
  const data = useSelector((state) => state.toggle_header);

  useEffect(() => {
    dispatch(quotationAction.fetchAllQuotations({ quotationParams }));
  }, [dispatch, debouncedText, currentPage, pageSize]);

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleQuotationModal();
    }
  }, [apiResponseStatus])

  const toggleQuotationModal = (value) => {
    setQuotationModalOpen();
  }

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const handlePageChange = (paginationData) => {
    setCurrentPage(paginationData.current);
    setPageSize(paginationData.pageSize);
    setProductParams(prevParams => ({
      ...prevParams,
      pageNumber: paginationData.current,
      pageSize: paginationData.pageSize,
    }));
  };

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const handleDataIntoExcel = (filename = 'Quotations.xlsx') => {
    if (dataSource && dataSource.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Sr.', 'supplierName', 'quotationNo', 'quotationDate', 'status', 'grandTotal', 'paid', 'due']];

    const tableData = dataSource.map((item, index) => [
      index + 1,
      item.supplierName,
      item.quotationNo,
      item.quotationDate,
      item.status,
      item.grandTotal,
      item.paid,
      item.due,
    ]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('Quotations.pdf');
  };

  const handleRefresh = () => {
    dispatch(quotationAction.fetchAllQuotations({ quotationParams }));
  }

  const columns = [

    {
      title: <IntlMessages id="purchases.quotations.table.column.supplier.name" />,
      dataIndex: "supplierName",
      sorter: (a, b) => a.supplierName.length - b.supplierName.length,
    },
    {
      title: <IntlMessages id="purchases.quotations.table.column.quotation" />,
      dataIndex: "quotationNo",
      sorter: (a, b) => a.quotationNo.length - b.quotationNo.length,
    },
    {
      title: <IntlMessages id="purchases.quotations.table.column.date" />,
      dataIndex: "quotationDate",
      render: (text) => {
        return <div>{filterDate(text)}</div>
      },
      sorter: (a, b) => new Date(a.quotationDate) - new Date(b.quotationDate),
    },
    {
      title: <IntlMessages id="purchases.quotations.table.column.status" />,
      dataIndex: "status",
      render: (text) => (
        <div>
          <span className={
            text === "Pending"
              ? "badge badge-danger"
              : text === "Received"
                ? "badge badge-success"
                : text
                  ? "badge badge-linesuccess"
                  : "badge badge-linedanger"
          }>
            {text}
          </span>
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: <IntlMessages id="purchases.quotations.table.column.grand.total" />,
      dataIndex: "grandTotal",
      sorter: (a, b) => a.grandTotal.length - b.grandTotal.length,
    },
    {
      title: <IntlMessages id="purchases.quotations.table.column.paid" />,
      dataIndex: "paid",
      sorter: (a, b) => a.paid.length - b.paid.length,
    },
    {
      title: <IntlMessages id="purchases.quotations.table.column.due" />,
      dataIndex: "due",
      sorter: (a, b) => a.due.length - b.due.length,
    },


    {
      title: <IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          {row.status === 'Pending' && (
            <div className="edit-delete-action">
              <Link
                className="me-2 p-2"
                onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
                data-bs-target="#edit-units"
              >
                <i data-feather="edit" className="feather-edit"></i>
              </Link>
              <Link className="confirm-text p-2">
                <i
                  data-feather="trash-2"
                  className="feather-trash-2"
                  onClick={() => showConfirmationAlert(row)}
                ></i>
              </Link>
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleModal = (quotationData, actionName) => {

    const quotationModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? quotationData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(quotationModalObj);
    setQuotationModalOpen(true);
  };

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(quotationAction.deleteQuotations({ quotationParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  const handleSearch = textValue => {
    setquotationParams((prevParams) => ({
      ...prevParams,
      searchText: textValue,
    }));
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="purchases.quotations.table.heading.purchases.quotations" /></h4>
                <h6><IntlMessages id="purchases.quotations.table.label.managepurchases.quotations" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link
                    onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                <IntlMessages id="purchases.quotations.table.button.addnewquotation" />
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      onChange={(e) => handleSearch(e.target.value)}

                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Filter */}

            </div>

            {isQuotationModalOpen ? (
              <AddQuotationModal
                currentModalData={currentModalData}
                isModalOpen={isQuotationModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={toggleQuotationModal}
              />
            ) : null}
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                isLoading={isLoading}
                columns={columns}
                dataSource={tableData}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: tableData?.totalRecords,
                  showSizeChanger: false,
                }}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </>
  );
};

export default PurchasesQuotationsList;