import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../../core/redux/action";
import Select from "react-select";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useDebounce } from 'use-debounce';
import Table from "../../../core/pagination/datatable";
import AddCreditNoteModal from "../creditnotes/addcreditnote";
import creditnotesAction from "../../../core/Services/purchases/creditnotes/actions.js";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import apiResponseAction from '../../../core/Services/apiResponse/actions.js';
import * as purchaseSelectors from '../purchasesSelector.js';
import * as ApiResponseSelectors from '../../selectors.js';

const CreditNotesList = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [creditNoteParams, setCreditNotesParams] = useState({
    pageSize: 10,
    pageNumber: 1,
    searchText: '',
  });
  const [debouncedText] = useDebounce(creditNoteParams.searchText, 1000);

  const isLoading = useSelector(state => purchaseSelectors.setLoader(state));
  const purchaseInvoiceData = useSelector(state => purchaseSelectors.setCreditNotes(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const tableData = purchaseInvoiceData ? purchaseInvoiceData.toJS() : [];
  const dataSource = tableData ? tableData.data : [];
  console.log(dataSource);

  const toggleModal = (value) => {
    setModalOpen();
  }

  useEffect(() => {
    dispatch(creditnotesAction.fetchAllCreditNotes({ creditNoteParams }));
  }, [dispatch, debouncedText, currentPage, pageSize]);


  const handlePageChange = (paginationData) => {
    setCurrentPage(paginationData.current);
    setPageSize(paginationData.pageSize);
    setCreditNotesParams(prevParams => ({
      ...prevParams,
      pageNumber: paginationData.current,
      pageSize: paginationData.pageSize,
    }));
  };
  const handleModal = (creditnotesData, actionName) => {
    const creditNotesModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? creditnotesData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(creditNotesModalObj);
    setModalOpen(true);
  };

  const handleRefresh = () => {
    dispatch(creditnotesAction.fetchAllCreditNotes({ creditNoteParams }));
  }

  const handleSearch = textValue => {
    setCreditNotesParams((prevParams) => ({
      ...prevParams,
      searchText: textValue,
    }));
  };

  const columns = [
    {
      title: <IntlMessages id="credit.notes.table.column.payment.voucher" />,
      dataIndex: "voucharNo",
      sorter: (a, b) => a.supplierName.length - b.supplierName.length,
    },
    {
      title: <IntlMessages id="credit.notes.table.column.cr" />,
      dataIndex: "isCredit",
      sorter: (a, b) => a.reference.length - b.reference.length,
      render: (text, row) => (
        <div>True</div>
      )
    },
    {
      title: <IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">

            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              //data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const data = useSelector((state) => state.toggle_header);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(creditnotesAction.deleteCreditNoteById({ creditNoteParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="purchases.credit.notes.page.title" /></h4>
                <p><IntlMessages id="purchases.credit.notes.page.manage.subtitle" /></p>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link onClick={() => handleGeneratePDF()}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <Link
                  onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                  className="btn btn-added"
                >
                  <PlusCircle className="me-2" />
                  <IntlMessages id="purchases.credit.notes.page.add.new.button.text" />
                </Link>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              {isModalOpen ? (
                <AddCreditNoteModal
                  currentModalData={currentModalData}
                  isModalOpen={isModalOpen}
                  isUpdate={currentModalData.formData.id ? true : false}
                  toggleModal={toggleModal}
                  creditNoteParams={creditNoteParams}
                />
              ) : null}        
              <div className="table-responsive product-list">
                <Table
                  isLoading={isLoading}
                  columns={columns}
                  dataSource={tableData}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: tableData?.totalRecords,
                    showSizeChanger: false,
                  }}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditNotesList;
