import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import { Link } from "react-router-dom";
import { PlusCircle } from "feather-icons-react/build/IconComponents";
import { useDebounce } from 'use-debounce';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import quotationActions from '../../../core/Services/purchases/quotations/actions.js';
import commonAction from '../../../core/Services/common/actions';
import ReactSelect from 'react-select';
import IntlMessages from "../../../shared/components/utility/intlMessages";
import AddSupplierModal from "../../people/suppliers/addsuppliers.js";
import * as ApiResponseSelectors from '../../selectors';
import TextArea from 'antd/es/input/TextArea.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import * as commonSelectors from '../../commonSelectors';

import './quotation.css';

const AddQuotation = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, quotationParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [quotationDetails, setQuotationDetails] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [productParams, setProductParams] = useState({ searchText: '' });
    const [debouncedText] = useDebounce(productParams.searchText, 1000);
    const [selectedDate, setSelectedDate] = useState(null);

    const [isSupplierModalOpen, setSupplierModalOpen] = useState(false);
    const [currentSupplierModalData, setCurrentSupplierModalData] = useState({});

    const productsBySearchDataSource = useSelector(state => commonSelectors.setAllProductsBySearch(state));
    const searchedOptions = productsBySearchDataSource ? productsBySearchDataSource.toJS() : [];
    const isLoading = useSelector(state => commonSelectors.setLoader(state));

    useEffect(() => {
        dispatch(commonAction.getAllSuppliers());
    }, []);

    const supplierDataSource = useSelector(state => commonSelectors.setAllSuppliers(state));
    const suppliers = supplierDataSource ? supplierDataSource.toJS() : [];

    useEffect(() => {
        if (isUpdate && currentModalData) {
            setSelectedDate(currentModalData.formData.quotationDate ? new Date(currentModalData.formData.quotationDate) : null);
        }
    }, [isUpdate, currentModalData]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        form.setFieldsValue({ quotationDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    useEffect(() => {
        const filteredOptions = searchedOptions.filter(
            product => !quotationDetails?.some(detail => detail.productId === product.id)
        );

        setProductOptions(filteredOptions?.map(product => ({
            label: product.productName,
            value: product.id,
            purchasePrice: product.purchasePrice,
            productName: product.productName,
        })));
    }, [searchedOptions, quotationDetails]);

    useEffect(() => {
        if (debouncedText) {
            dispatch(commonAction.getAllProductsBySearch({ productParams: debouncedText }));
        }
    }, [debouncedText, dispatch]);

    useEffect(() => {
        setQuotationDetails(currentModalData.formData.quotationDetails);
    }, [currentModalData]);

    const handleProductSearch = textValue => {
        setProductParams({
            ...productParams,
            searchText: textValue,
        });
    };

    const toggleSupplierModal = () => {
        dispatch(commonAction.getAllSuppliers({}));
        setSupplierModalOpen(false);
    };
    const handleModal = (supplierData, actionName) => {
        const supplierModalObj = {
            formData: {},
        };
        setCurrentSupplierModalData(supplierModalObj);
        setSupplierModalOpen(true);
    };

    const handleProductChange = (selectedProduct) => {
        if (selectedProduct) {
            const product = quotationDetails != undefined ? quotationDetails.filter(p => p.productName == selectedProduct.productName).length : 0;
            if (product > 0) {
                return;
            }
            if (quotationDetails != undefined) {
                setQuotationDetails(prevDetails => [
                    ...prevDetails,
                    {
                        quotationId: 0,
                        productId: selectedProduct.value,
                        productName: selectedProduct.productName,
                        quantity: 1,
                        unitPrice: selectedProduct.purchasePrice,
                        disscount: 0,
                        taxPercentage: 0,
                        taxAmount: 0,
                        totalCost: selectedProduct.purchasePrice,
                    }
                ]);
            }
            else {
                setQuotationDetails([
                    {
                        quotationId: 0,
                        productId: selectedProduct.value,
                        productName: selectedProduct.productName,
                        quantity: 1,
                        unitPrice: selectedProduct.purchasePrice,
                        disscount: 0,
                        taxPercentage: 0,
                        taxAmount: 0,
                        totalCost: selectedProduct.purchasePrice,
                    }]);
            }
        }
    };

    const handleQuantityChange = (value, index) => {
        const updatedDetails = [...quotationDetails];
        const product = updatedDetails[index];
        product.quantity = value;
        product.totalCost = (product.quantity * product.unitPrice) - product.disscount + product.taxAmount;
        setQuotationDetails(updatedDetails);
    };

    const handledisscountChange = (value, index) => {
        const updatedDetails = [...quotationDetails];
        const product = updatedDetails[index];
        product.disscount = value;
        product.totalCost = (product.quantity * product.unitPrice) - product.disscount + product.taxAmount;
        setQuotationDetails(updatedDetails);
    };

    const handleTaxPercentageChange = (value, index) => {
        const updatedDetails = [...quotationDetails];
        const product = updatedDetails[index];
        product.taxPercentage = value;
        product.taxAmount = ((product.taxPercentage / 100) * product.unitPrice * product.quantity);
        product.totalCost = (product.quantity * product.unitPrice) - product.disscount + product.taxAmount;
        setQuotationDetails(updatedDetails);
    };

    const handleRemoveProduct = (index) => {
        const updatedDetails = [...quotationDetails];
        updatedDetails.splice(index, 1);
        setQuotationDetails(updatedDetails);
    };

    const handleRecord = (actionName, quotationData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            quotationData.id = currentModalData.formData.id;
            quotationData.companyId = currentModalData.formData.companyId;
        }
        quotationData.companyId = currentModalData.formData ? currentModalData.formData.companyId : 0;
        quotationData.quotationDetails = quotationDetails;
        dispatch(quotationActions.addUpdateQuotations({ quotationData, actionName, QuotationParams: quotationParams }));
        toggleModal();
    };

    return (
        <Modal
            open={isModalOpen}
            width={1000}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="quotation.inventory.form.modal.title.update" />
                ) : (
                    <IntlMessages id="quotation.inventory.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="quotation.inventory.form.modal.update" />
                ) : (
                    <IntlMessages id="quotation.inventory.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="quotation.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="quotationForm"
                className="quotationActions_addModalWrapper"
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    supplierId: currentModalData.formData ? currentModalData.formData.supplierId : 0,
                    quotationDate: currentModalData.formData ? moment(currentModalData.formData.quotationDate).format('YYYY-MM-DD') : null,
                    otherTax: currentModalData.formData ? currentModalData.formData.otherTax : 0,
                    disscount: currentModalData.formData ? currentModalData.formData.disscount : 0,
                    shippingCost: currentModalData.formData ? currentModalData.formData.shippingCost : 0,
                    quotationNotes: currentModalData.formData ? currentModalData.formData.quotationNotes : '',
                    quotationDetails: currentModalData.formData ? currentModalData.formData.quotationDetails
                        : [{
                            "quotationId": 0,
                            "productId": 0,
                            "quantity": 0,
                            "unitPrice": 0,
                            "disscount": 0,
                            "taxPercentage": 0,
                            "taxAmount": 0,
                            "totalCost": 0
                        }]
                }}
            >
                <div className="card-body">
                    <Spin spinning={isLoading} size="large">
                        <div className="row mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-blocks add-product">
                                    <div className="row align-items-center">
                                        <div className="col-lg-10">
                                            <div className="input-blocks">
                                                <Form.Item
                                                    name="supplierId"
                                                    label={
                                                        <IntlMessages id="purchases.quotations.form.label.supplier.name" />
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: <IntlMessages id="purchases.quotations.form.validation.supplier.name" />,
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={suppliers?.map(f => ({ label: f.name, value: f.id }))}
                                                        classNamePrefix="react-select"
                                                        className="input-select"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        notFoundContent="No results found"
                                                        placeholder={<IntlMessages id="purchases.quotations.form.placeholder.choose" />}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 ps-0">
                                            <div className="add-icon tab mt-1">
                                                <Link
                                                    className="btn btn-filter"
                                                    onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                                                >
                                                    <PlusCircle className="feather feather-plus-circle" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="quotationDate"
                                        label={<IntlMessages id="purchases.quotations.form.label.purchase.date" />}
                                        rules={[
                                            {
                                                required: true,
                                                message: <IntlMessages id="purchases.quotations.form.validation.purchase.date" />,
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select Date"
                                            className="actions_addModalFormItem"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="input-blocks">
                                    <ReactSelect
                                        options={productOptions}
                                        isClearable
                                        onInputChange={handleProductSearch}
                                        onChange={handleProductChange}
                                        placeholder={CONSTANT_VARIABLES.SELECT_PRODUCT}
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="modal-body-table">
                                    <div className="table-responsive">
                                        <table className="table datanew">
                                            <thead>
                                                <tr>
                                                    <th><IntlMessages id="purchases.quotations.table.column.product" /></th>
                                                    <th><IntlMessages id="purchases.quotations.table.column.qty" /></th>
                                                    <th><IntlMessages id="purchases.quotations.table.column.purchase.price" /></th>
                                                    <th><IntlMessages id="purchases.quotations.table.column.disscount" /></th>
                                                    <th><IntlMessages id="purchases.quotations.table.column.tax" /></th>
                                                    <th><IntlMessages id="purchases.quotations.table.column.tax.amount" /></th>
                                                    <th><IntlMessages id="purchases.quotations.table.column.total.cost" /></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {quotationDetails?.map((product, index) => (
                                                    <tr key={index}>
                                                        <td className="p-3">{product.productName}</td>
                                                        <td className="p-3">
                                                            <Input
                                                                type="number"
                                                                min={1}
                                                                value={product.quantity}
                                                                onChange={(e) => handleQuantityChange(Number(e.target.value), index)}
                                                               className='table_input_width-100'
                                                            />
                                                        </td>
                                                        <td className="p-3">
                                                            {product.unitPrice}
                                                        </td>
                                                        <td className="p-3">
                                                            <Input
                                                                type="number"
                                                                min={0}
                                                                value={product.disscount}
                                                                onChange={(e) => handledisscountChange(Number(e.target.value), index)}
                                                                className='table_input_width-100'
                                                            />
                                                        </td>
                                                        <td className="p-3">
                                                            <Input
                                                                type="number"
                                                                min={0}
                                                                value={product.taxPercentage}
                                                                className='table_input_width-100'
                                                                onChange={(e) => handleTaxPercentageChange(Number(e.target.value), index)}
                                                            />
                                                        </td>
                                                        <td className="p-3">{product.taxAmount.toFixed(2)}</td>
                                                        <td className="p-3">{product.totalCost.toFixed(2)}</td>
                                                        <td className="p-3">
                                                            <i
                                                                data-feather="trash-2"
                                                                className="feather-trash-2"
                                                                onClick={() => handleRemoveProduct(index)}
                                                            ></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="otherTax"
                                        label={<IntlMessages id="purchases.quotations.form.label.order.tax" />}
                                        initialValue={0}
                                        rules={[
                                            {
                                                type: 'number',
                                                min: 0,
                                                message: <IntlMessages id="purchase.purchase.modal.validation.valid.input" />,
                                            },
                                        ]}
                                    >
                                        <InputNumber min={0} className="input_number" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="disscount"
                                        label={<IntlMessages id="purchases.quotations.form.label.disscount" />}
                                        initialValue={0}
                                        rules={[
                                            {
                                                type: 'number',
                                                min: 0,
                                                message: <IntlMessages id="purchase.purchase.modal.validation.valid.input" />,
                                            },
                                        ]}
                                    >
                                        <InputNumber min={0} className="input_number" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="shippingCost"
                                        label={<IntlMessages id="purchases.quotations.form.label.shipping" />}
                                        initialValue={0}
                                        rules={[
                                            {
                                                type: 'number',
                                                min: 0,
                                                message: <IntlMessages id="purchase.purchase.modal.validation.valid.input" />,
                                            },
                                        ]}
                                    >
                                        <InputNumber min={0} className="input_number" />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12">
                                <div className="input-blocks">
                                    <Form.Item
                                        name="quotationNotes"
                                        className='warehouseActions_addModalFormItem'
                                        label={
                                            <IntlMessages id="purchases.quotations.form.label.notes" />
                                        }
                                    >
                                        <TextArea placeholder={CONSTANT_VARIABLES.CUSTOMER_DESCRIPTION} />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        {isSupplierModalOpen ? (
                            <AddSupplierModal
                                currentModalData={currentSupplierModalData}
                                isModalOpen={isSupplierModalOpen}
                                isUpdate={false}
                                toggleModal={toggleSupplierModal}
                            />
                        ) : null}
                    </Spin>
                </div>
            </Form>
        </Modal >
    );
};

export default AddQuotation;
