export const setUploadProfileImage = state => {
    return state.services.common.getIn(['uploadImageUrl']);
 };

 export const setAllStores = state => {
    return state.services.common.getIn(['allStoresData']);
};

export const setAllWarehouses = state => {
    return state.services.common.getIn(['allWarehousesData']);
};

export const setAllCategory = state => {
    return state.services.common.getIn(['allCategoryData']);
};

export const setAllSubCategory = state => {
    return state.services.common.getIn(['allSubCategoryData']);
};
export const setAllPurchaseVouchers = state => {
    return state.services.common.getIn(['allPurchaseVouchersData']);
};
export const setReportLogoImage = state => {
    return state.services.common.getIn(['uploadReportLogoImageUrl']);
};

export const setAllBrands = state => {
    return state.services.common.getIn(['allBrandsData']);
};

export const setAllUnits = state => {
    return state.services.common.getIn(['allUnitsData']);
};

export const setAllUsers = state => {
    return state.services.common.getIn(['allUsersData']);
};

export const setAllVAraints = state => {
    return state.services.common.getIn(['allVariantsData']);
};

export const setAllExpenseCategory = state => {
    return state.services.common.getIn(['allExpenseCategoryData']);
};

export const setAllSellingTypes = state => {
    return state.services.common.getIn(['allSellingTypesData']);
};

export const setAllbarcodesymbologies = state => {
    return state.services.common.getIn(['allBarCodeSymbData']);
};

export const setAllDisscountTypes = state => {
    return state.services.common.getIn(['allDisscountTypesData']);
};

export const setAllTaxTypes = state => {
    return state.services.common.getIn(['allTaxTypesData']);
};
export const setAllPOSProductTypes = state => {
    return state.services.common.getIn(['allPOSProductTypesData']);
};

export const setAllSuppliers = state => {
    return state.services.common.getIn(['allSupplierData']);
};

export const setAllProductsBySearch = state => {
    return state.services.common.getIn(['allProductsDataBySearch']);
};

export const setLoader = state => {
    return state.services.common.getIn(['showLoader']);
 };

 export const setAllCustomer = state => {
    return state.services.common.getIn(['allCustomerData']);
};

export const setAllCountries = state => {
    return state.services.common.getIn(['allCountriesData']);
};

export const setAllStates = state => {
    return state.services.common.getIn(['allStatesData']);
};

export const setAllCities = state => {
    return state.services.common.getIn(['allCitiesData']);
};

export const setAllEmployee = state => {
    return state.services.common.getIn(['allEmployeeData']);
};

export const setAllLeaveType = state => {
    return state.services.common.getIn(['AllLeaveTypesData']);
};

export const setAllDesignation = state => {
    return state.services.common.getIn(['allDesignationData']);
};

export const setAllFunctionTypes = state => {
    return state.services.common.getIn(['allFuncTypeData']);
};


export const setAllDepartment = state => {
    return state.services.common.getIn(['allDepartmentData']);
};

export const setAllShift = state => {
    return state.services.common.getIn(['allShiftData']);
};

export const setAllGender = state => {
    return state.services.common.getIn(['allGenderData']);
};

export const setAllWeekoffs = state => {
    return state.services.common.getIn(['allWeekoffData']);
};

export const setAllNationalities = state => {
    return state.services.common.getIn(['allNationalityData']);
};

export const setAllBloodGroup = state => {
    return state.services.common.getIn(['allBloodGroupData']);
};

export const setAllSaleQuotation = state => {
    return state.services.common.getIn(['allSaleQuotationData']);
};

export const setAllQuotation = state => {
    return state.services.common.getIn(['allQuotationData']);
};

export const setAllGrnById = state => {
    return state.services.common.getIn(['allGrnData']);
};

export const setAllLeaveTypes = state => {
    return state.services.common.getIn(['AllLeaveTypesData']);
};

export const setAllSaleOrder = state => {
    return state.services.common.getIn(['allSaleOrderData']);
};

export const setAllInvoicesData = state => {
    return state.services.common.getIn(['AllInvoicesData']);
};

export const setProducts = state => {
    return state.services.common.getIn(['allProductData']);
};

export const setShipments = state => {
    return state.services.common.getIn(['allShipmentData']);
};

export const setSaleInvoices = state => {
    return state.services.common.getIn(['allSaleInvoiceData']);
};
export const setAllBanks = state => {
    return state.services.common.getIn(['allBanksData']);
};
export const setAllCurrency = state => {
    return state.services.common.getIn(['allCurrencyData']);
};
export const setLanguage = state => {
    return state.services.common.getIn(['allLanguageData']);
};

export const setOpttype = state => {
    return state.services.common.getIn(['allOpttypeData']);
};

export const setDateformate = state => {
    return state.services.common.getIn(['allDateformateData']);
};

export const setTimezone = state => {
    return state.services.common.getIn(['allTimezoneData']);
};

export const setTimeformate = state => {
    return state.services.common.getIn(['allTimeformateData']);
};

export const setTaxrate = state => {
    return state.services.common.getIn(['allTaxrateData']);
};

export const setAllExpensetype = state => {
    return state.services.common.getIn(['allExpensetypeData']);
};
export const setAllcustominputtype = state => {
    return state.services.common.getIn(['allcustominputtypeData']);
};
export const setAllconnectiontype = state => {
    return state.services.common.getIn(['allconnectiontypeData']);
};

export const setAllcustomfieldfor = state => {
    return state.services.common.getIn(['allcustomfieldforData']);
};

export const setAllPurchaseReceiveDrp = state => {
    return state.services.common.getIn(['allPurchaseReceiveData']);
};
