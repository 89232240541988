import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Select, Radio, Spin } from 'antd';
import CustomFormLabel from '../../../common/CustomFormLabel';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import commonActions from '../../../../core/Services/common/actions'
import * as commonSelectors from '../../../commonSelectors'

const CustomerModal = (props) => {
    const { isModalOpen, toggleModal } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const isLoading = useSelector(state => commonSelectors.setLoader(state));
    const allNationalitiesData = useSelector(state => commonSelectors.setAllNationalities(state));
    const nationalitiesData = allNationalitiesData ? allNationalitiesData.toJS() : [];

    const allCitiesData = useSelector(state => commonSelectors.setAllCities(state));
    const citiesData = allCitiesData ? allCitiesData.toJS() : [];

    useEffect(() => {
        dispatch(commonActions.getAllNationalities())
        dispatch(commonActions.getAllCities({countryParam: null}))
    }, [])

    return (
        <>
            <Modal
                open={isModalOpen}
                width={700}
                zIndex={99999}
                onClose={() => toggleModal(true)}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            handleRecord(false ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                        })
                        .catch(info => {
                            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                        });
                }}
                onCancel={() => toggleModal(true)}
                title={
                    false ? (
                        "Update Customer"
                    ) : (
                        "Add Customer"
                    )
                }
                okText={
                    false ? (
                        <IntlMessages id="purchases.purchase.form.modal.update" />
                    ) : (
                        <IntlMessages id="purchases.purchase.form.modal.add" />
                    )
                }
                cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
                maskClosable={false}
            >
                <Spin spinning={isLoading} size="large">
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{}}
                        style={{ marginTop: '20px' }}
                    >

                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks add-product">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-10 col-10">
                                            <Form.Item
                                                name="customerName"
                                                className="actions_addModalFormItem"
                                                label={
                                                    <CustomFormLabel
                                                        labelkey="Customer Name"
                                                        popoverkey="Customer Name"
                                                    />
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Customer Name Required",
                                                    },
                                                ]}
                                            >
                                                <Input type="text" placeholder='Enter Name' />
                                            </Form.Item>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks add-product">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-10 col-10">
                                            <Form.Item
                                                name="email"
                                                className="actions_addModalFormItem"
                                                label={
                                                    <CustomFormLabel
                                                        labelkey="Email"
                                                        popoverkey="Email"
                                                    />
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Email Required",
                                                    },
                                                ]}
                                            >
                                                <Input type="text" placeholder='Enter Email' />
                                            </Form.Item>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks add-product">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-10 col-10">
                                            <Form.Item
                                                name="customerName"
                                                className="actions_addModalFormItem"
                                                label={
                                                    <CustomFormLabel
                                                        labelkey="Phone"
                                                        popoverkey="Phone"
                                                    />
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Phone Required",
                                                    },
                                                ]}
                                            >
                                                <Input type="text" placeholder='Enter Phone' />
                                            </Form.Item>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks add-product">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-10 col-10">
                                            <Form.Item
                                                name="email"
                                                className="actions_addModalFormItem"
                                                label={
                                                    <CustomFormLabel
                                                        labelkey="Country"
                                                        popoverkey="Country"
                                                    />
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Country Required",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    classNamePrefix="react-select"
                                                    options={nationalitiesData.map(s => ({ label: s.name, value: s.id }))}
                                                    placeholder="Choose"
                                                    className="input-select"
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.label.toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    notFoundContent="No results found"
                                                />
                                            </Form.Item>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks add-product">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-10 col-10">
                                            <Form.Item
                                                name="customerName"
                                                className="actions_addModalFormItem"
                                                label={
                                                    <CustomFormLabel
                                                        labelkey="Customer Name"
                                                        popoverkey="Customer Name"
                                                    />
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Customer Name Required",
                                                    },
                                                ]}
                                            >
                                                <Input type="text" placeholder='Enter Name' />
                                            </Form.Item>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="input-blocks add-product">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-10 col-10">
                                            <Form.Item
                                                name="email"
                                                className="actions_addModalFormItem"
                                                label={
                                                    <CustomFormLabel
                                                        labelkey="Address"
                                                        popoverkey="Address"
                                                    />
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Address Required",
                                                    },
                                                ]}
                                            >
                                                <Input type="text" placeholder='Enter Address' />
                                            </Form.Item>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};

export default CustomerModal;
