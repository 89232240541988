export const ActionTypes = {
  ADD_UPDATE_POS_OTHER_ORDER: 'services/pos/pointofsale/add_update_pos_other_order',
  FETCH_POS_OTHERS_PRODUCT_BY_SUBCATEGORY: 'services/pos/pointofsale/fetch_pos_others_product_by_subcategory',
  SET_POS_OTHERS_PRODUCT_BY_SUBCATEGORY: 'services/pos/pointofsale/set_pos_others_product_by_subcategory',
  FETCH_POS_OTHERS_CATEGORIES: 'services/pos/pointofsale/fetch_pos_others_categories',
  SET_POS_OTHERS_CATEGORIES: 'services/pos/pointofsale/set_pos_others_categories',
  SET_LOADER: 'services/pos/pointofsale/set_loader',
}

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchAllposotherscategories = () => ({
  type: ActionTypes.FETCH_POS_OTHERS_CATEGORIES,
})

  
const setposotherscategories = ({ allCategoryData }) => ({
  allCategoryData,
  type: ActionTypes.SET_POS_OTHERS_CATEGORIES,
})

const fetchAllposothersproductsbycategory = ({ categoryParams, categoryId }) => ({
  categoryParams,
  categoryId,
  type: ActionTypes.FETCH_POS_OTHERS_PRODUCT_BY_SUBCATEGORY,
})

const setposothersproductsbycategory = ({ allsubcategoryData }) => ({
  allsubcategoryData,
  type: ActionTypes.SET_POS_OTHERS_PRODUCT_BY_SUBCATEGORY,
})


const addUpdateposotherorder = ({ posotherorderData, actionName, UserParams }) => ({
  posotherorderData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_POS_OTHER_ORDER,
})

export default {
  addUpdateposotherorder,
  fetchAllposotherscategories,
  setposotherscategories,
  setposothersproductsbycategory,
  fetchAllposothersproductsbycategory,
  setLoader
}