import React, { useState, useRef, useEffect, } from 'react';
import { Modal, Form, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash } from "feather-icons-react/build/IconComponents";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import headActions from '../../../core/Services/purchases/heads/actions';
import creditNoteActions from '../../../core/Services/purchases/creditnotes/actions';
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import * as purchaseSelector from '../purchasesSelector';
import moment from 'moment';

const AddCreditNoteModal = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, creditNoteParams } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const allPurchaseVouchersData = useSelector(state => commonSelector.setAllPurchaseVouchers(state));
  const allHeadTypeData = useSelector(state => purchaseSelector.setHeads(state));
  const headTypeDataSource = allHeadTypeData ? allHeadTypeData.toJS() : [];
  const purchaseVouchersDataSource = allPurchaseVouchersData ? allPurchaseVouchersData.toJS() : [];
  const isLoading = useSelector(state => commonSelector.setLoader(state));

  console.log(headTypeDataSource);
  const [rows, setRows] = useState([{ id: 1 }]);

  const addRow = () => {
    setRows([...rows, { id: rows.length + 1, crDrNoteId: 0, headId: 0, creditNo: '', amount: 0, checkNO: 0, checkDate: new Date(), description: '' }]);
  };

  const deleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  useEffect(() => {
    if (isUpdate) {
      currentModalData.formData?.crDrNoteDetails.forEach((row) => {
        setRows((prevRows) => {
          const rowExists = prevRows.some((prevRow) => prevRow.id === row.id);
          if (!rowExists) {
            return [row, ...prevRows];
          }
          return prevRows;
        });
      });
    }
  }, [currentModalData]);

  useEffect(() => {
    dispatch(commonAction.getAllPurchaseVouchers());
    dispatch(headActions.fetchAllHeads({ headParams: '' }));
  }, [dispatch])

  const handleRecord = (actionName, creditNoteData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      creditNoteData.id = currentModalData.formData?.id;
      creditNoteData.companyId = currentModalData.formData?.companyId;
      creditNoteData.isCredit = true;
      creditNoteData.crDrNoteDetails = rows.filter(c => c.headId > 0).map(detail => ({
        ...detail,
        crDrNoteId: 0,
        id: 0
      }));
    } else {
      creditNoteData.id = 0;
      creditNoteData.companyId = 0;
      creditNoteData.crDrNoteDetails = rows.length > 1 ? rows.slice(0,rows.length - 1).map(detail => ({
        ...detail,
        crDrNoteId: 0,
        id: 0
      })):rows.map(detail => ({
        ...detail,
        crDrNoteId: 0,
        id: 0
      }));
      creditNoteData.isCredit = true;
    }
    dispatch(creditNoteActions.addUpdateCreditNote({ creditNoteData, actionName, creditNoteParams }));
    toggleModal();
  };

  const handleFieldChange = (id, field, value) => {
    setRows(rows.map(row => (row.id === id ? { ...row, [field]: value } : row)));
  };

  return (
    <Modal
      open={isModalOpen}
      width={1140}
      zIndex={99999}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="purchase.credit.notes.form.modal.title.update" />
        ) : (
          <IntlMessages id="purchase.credit.notes.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          purchaseVoucharId: currentModalData.formData?.purchaseVoucharId,
        }}
      >
        <div className="card-body">
          <Spin spinning={isLoading} size="large">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="input-blocks add-product">
                  <div className="row">
                    <div className="col-lg-10 col-sm-10 col-10">
                      <Form.Item
                        name="purchaseVoucharId"
                        className='actions_addModalFormItem'
                        label={
                          <CustomFormLabel
                            labelkey="purchase.creditnote.form.purchase.order.label.name"
                            popoverkey="purchase.creditnote.form.label.popover.purchase.order.name"
                          />
                        }
                        rules={[
                          {
                            required: true,
                            message: <IntlMessages id="purchase.creditnote.modal.validation.purchase.order.name" />,
                          },
                        ]}
                      >
                        <Select
                          options={purchaseVouchersDataSource.map(f => ({ label: f.name, value: f.id }))}
                          classNamePrefix="react-select"
                          className="input-select"
                          placeholder="Choose"
                        />
                      </Form.Item>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="modal-body-table">
                  <div className="table-responsive">
                    <table className="table  datanew">
                      <thead>
                        <tr>
                          <th><IntlMessages id="purchases.account.ledger.modal.validation.account.ledger" /></th>
                          <th><IntlMessages id="purchases.account.ledger.modal.validation.credit.no" /></th>
                          <th><IntlMessages id="purchases.account.ledger.modal.validation.amount" /></th>
                          <th><IntlMessages id="purchases.account.ledger.modal.validation.check.no" /></th>
                          <th><IntlMessages id="purchases.account.ledger.modal.validation.check.date" /></th>
                          <th><IntlMessages id="purchases.account.ledger.modal.validation.description" /></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows?.map((row, index) => (
                          <tr key={row.id}>
                            <td>
                              <Select
                                options={headTypeDataSource.data?.map(f => ({ label: f.name, value: f.id }))}
                                classNamePrefix="react-select"
                                placeholder="Choose"
                                value={row.headId}
                                onChange={(e) => handleFieldChange(row.id, 'headId', e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Credit No"
                                value={row.creditNo}
                                onChange={(e) => handleFieldChange(row.id, 'creditNo', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Amount"
                                value={row.amount}
                                onChange={(e) => handleFieldChange(row.id, 'amount', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Check No"
                                value={row.checkNO}
                                onChange={(e) => handleFieldChange(row.id, 'checkNO', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                className="form-control"
                                value={moment(row.checkDate).format('YYYY-MM-DD')}
                                onChange={(e) => handleFieldChange(row.id, 'checkDate', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Description"
                                value={row.description}
                                onChange={(e) => handleFieldChange(row.id, 'description', e.target.value)}
                              />
                            </td>
                            <td>
                              {index === rows.length - 1 ? (
                                <button onClick={addRow} className="me-2 p-2 btn btn-success">
                                  <PlusCircle className="me-2" />
                                </button>
                              ) : (
                                <button
                                  onClick={() => deleteRow(row.id)}
                                  className="me-2 p-2 btn btn-danger"
                                >
                                  <Trash className="me-2" />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            </Spin>
            </div>
          </Form>
        </Modal>
        );
};

        export default AddCreditNoteModal;
