import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setSaleReturns = (state, { allSaleReturnData }) => {
    if (!allSaleReturnData) return state;
    return state.merge({ allSaleReturnData });
}

const setSaleReturnById = (state, { saleReturnParam }) => {
    return state.merge({ saleReturnParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setQuotationNoById = (state, { QuotationData }) => {
    return state.merge({ QuotationData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_SALES_RETURN,
        actionFunction: setSaleReturns,
    },
    {
        actionType: ActionTypes.SET_SALE_RETURN_BY_ID,
        actionFunction: setSaleReturnById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_NO_BY_ID,
        actionFunction: setQuotationNoById,
    },
];
