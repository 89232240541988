import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../utils/ss-api';
import Actions, { ActionTypes } from '../common/actions';
import notification from "../../../shared/components/Notification";
import Immutable from 'immutable';
import { CONSTANT_VARIABLES } from "../../Common/Constant_Variables/constants";

function* uploadProfileImage({ userImage }) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const file = userImage.originFileObj;
        const formData = new FormData();
        formData.append('file', file); // Use 'file' instead of 'File'

        const { data } = yield call(api.UploadImage, {
            route: `/Storage/upload`,
            data: formData,
        });
        const uploadImageUrl = getImmutableData(data);
        yield put(Actions.setUploadProfileImage({ uploadImageUrl }));
        yield put(Actions.setLoader({ showLoader: false }));
        notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllStores() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-storesdrp' });
        const allStoresData = getImmutableData(data);
        yield put(Actions.setAllStores({ allStoresData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllWarehouses() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-warehousesdrp' });
        const allWarehousesData = getImmutableData(data);
        yield put(Actions.setAllWarehouses({ allWarehousesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllCategory() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-categoriesdrp' });
        const allCategoryData = getImmutableData(data);
        yield put(Actions.setAllCategory({ allCategoryData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllSubCategory({ CategoryId }) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-subcategoriesdrp?CategoryId=' + CategoryId });
        const allSubCategoryData = getImmutableData(data);
        yield put(Actions.setAllSubCategory({ allSubCategoryData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllBrands() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-brandsdrp' });
        const allBrandsData = getImmutableData(data);
        yield put(Actions.setAllBrands({ allBrandsData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllUnits() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-unitsdrp' });
        const allUnitsData = getImmutableData(data);
        yield put(Actions.setAllUnits({ allUnitsData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllUsers() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/UserMGT/Getusers' });
        const allUsersData = getImmutableData(data);
        yield put(Actions.setAllUsers({ allUsersData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllExpenseCategory() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/FinanceAccounts/all-expcategoriesdrp' });
        const allExpenseCategoryData = getImmutableData(data);
        yield put(Actions.setAllExpenseCategory({ allExpenseCategoryData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllVariants() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-variantsdrp' });
        const allVariantsData = getImmutableData(data);
        yield put(Actions.setAllVariants({ allVariantsData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllSellingTypes() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-sellingtypesdrp' });
        const allSellingTypesData = getImmutableData(data);
        yield put(Actions.setAllSellingTypes({ allSellingTypesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllbarcodesymbologies() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-barcodesymbologydrp' });
        const allBarCodeSymbData = getImmutableData(data);
        yield put(Actions.setAllbarcodesymbologies({ allBarCodeSymbData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllDisscountTypes() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-disscounttypesdrp' });
        const allDisscountTypesData = getImmutableData(data);
        yield put(Actions.setAllDisscountTypes({ allDisscountTypesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllTaxTypes() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Inventory/all-taxtypesdrp' });
        const allTaxTypesData = getImmutableData(data);
        yield put(Actions.setAllTaxTypes({ allTaxTypesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllPOSProductTypes() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/POS/get-posproducttypedrp' });
        const allPOSProductTypesData = data;
        yield put(Actions.setAllPOSProductTypes({ allPOSProductTypesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllCities({getAllCities}) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-citiesdrp' });
        const allCitiesData = getImmutableData(data);
        yield put(Actions.setAllCities({ allCitiesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllCitiesByStateId({ cityParams }) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: `/Setup/all-citiesdrp/?StateId=${cityParams}` });
        const allCitiesData = getImmutableData(data);
        yield put(Actions.setAllCities({ allCitiesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllStates() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-statesdrp' });
        const allStatesData = getImmutableData(data);
        yield put(Actions.setAllStates({ allStatesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllStatesByCountryId({ stateParams }) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: `/Setup/all-statesdrp/?CountryId=${stateParams}`,
            // params: stateParams?.id,
        });
        const allStatesData = getImmutableData(data);
        yield put(Actions.setAllStates({ allStatesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllCountries() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-countriesdrp' });
        const allCountriesData = getImmutableData(data);
        yield put(Actions.setAllCountries({ allCountriesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllCustomer() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-customersdrp' });
        const allCustomerData = getImmutableData(data);
        yield put(Actions.setAllCustomer({ allCustomerData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllQuotation() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/Purchases/all-quotationsdrp',
        });
        const allQuotationData = getImmutableData(data);
        yield put(Actions.setAllQuotation({ allQuotationData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* getAllSuppliers() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/Setup/all-suppliersdrp' });
        const allSupplierData = getImmutableData(data);

        yield put(Actions.setAllSuppliers({ allSupplierData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* getAllProductsBySearch({ productParams }) {
    console.log("saga product params", productParams);
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/Inventory/all-productsearch?SearchText=' + productParams,
        });
        const allProductsDataBySearch = getImmutableData(data);

        yield put(Actions.setAllProductsBySearch({ allProductsDataBySearch }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllEmployee() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-employeesdrp',
        });
        const allEmployeeData = getImmutableData(data);
        yield put(Actions.setAllEmployee({ allEmployeeData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllLeaveType() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-leavetypesdrp',
        });
        const allLeaveTypeData = getImmutableData(data);
        yield put(Actions.setAllLeaveType({ allLeaveTypeData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllDesignation() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-designationsdrp',
        });
        const allDesignationData = getImmutableData(data);
        yield put(Actions.setAllDesignation({ allDesignationData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllDepartment() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-departmentsdrp',
        });
        const allDepartmentData = getImmutableData(data);
        yield put(Actions.setAllDepartment({ allDepartmentData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* getAllFunctionTypes() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/POS/all-functiontypes',
        });
        const allFuncTypeData = getImmutableData(data);
        yield put(Actions.setAllFunctionTypes({ allFuncTypeData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllShift() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-shiftsdrp',
        });
        const allShiftData = getImmutableData(data);
        yield put(Actions.setAllShift({ allShiftData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllGender() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-gendersdrp',
        });
        const allGenderData = getImmutableData(data);
        yield put(Actions.setAllGender({ allGenderData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllWeekoffs() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-weekoffsdrp',
        });
        const allWeekoffData = getImmutableData(data);
        yield put(Actions.setAllWeekoffs({ allWeekoffData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllNationalities() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-nationalitiesdrp',
        });
        const allNationalityData = getImmutableData(data);
        yield put(Actions.setAllNationalities({ allNationalityData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllBloodGroup() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-bloodgroupsdrp',
        });
        const allBloodGroupData = getImmutableData(data);
        yield put(Actions.setAllBloodGroup({ allBloodGroupData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* getAllGrnById() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/HRM/all-blood-groupsdrp',
        });
        const allGrnData = getImmutableData(data);
        yield put(Actions.setAllGrnById({ allGrnData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* getAllLeaveTypes() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, { route: '/HRM/all-leavetypesdrp' });
        const AllLeaveTypesData = getImmutableData(data);
        yield put(Actions.setAllLeaveTypes({ AllLeaveTypesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* GetAllSaleQuotation() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/Sales/all-salequotationsdrp',
        });
        const allSaleQuotationData = getImmutableData(data);
        yield put(Actions.setAllSaleQuotation({ allSaleQuotationData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* getAllSaleOrder() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/Sales/all-saleordersdrp',
        });
        const allSaleOrderData = getImmutableData(data);
        yield put(Actions.setAllSaleOrder({ allSaleOrderData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* getAllInvoicesData() {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const { data } = yield call(api.get, {
            route: '/Purchases/all-purchaseinvoicesdrp',
        });
        const AllInvoicesData = getImmutableData(data);
        yield put(Actions.setAllInvoicesData({ AllInvoicesData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function* fetchAllProducts() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch products
        const { data } = yield call(api.get, {
            route: '/Inventory/all-productsdrp'
        });

        // Store the fetched data in Redux store
        const allProductData = getImmutableData(data);
        yield put(Actions.setProducts({ allProductData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}


function* fetchAllShipments() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch shipments
        const { data } = yield call(api.get, {
            route: '/Sales/all-shipmentsdrp',
        });

        // Store the fetched data in Redux store
        const allShipmentData = getImmutableData(data);
        yield put(Actions.setShipments({ allShipmentData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllSaleInvoices() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch sale invoices
        const { data } = yield call(api.get, {
            route: '/Sales/all-saleinvoicesdrp',
        });

        // Store the fetched data in Redux store
        const allSaleInvoiceData = getImmutableData(data);
        yield put(Actions.setSaleInvoices({ allSaleInvoiceData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllBanks() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch sale invoices
        const { data } = yield call(api.get, {
            route: '/Sales/all-banksdrp',
        });

        // Store the fetched data in Redux store
        const allBanksData = getImmutableData(data);
        yield put(Actions.setAllBanks({ allBanksData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllcurrency() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch sale invoices
        const { data } = yield call(api.get, {
            route: '/Settings/all-currenciesdrp',
        });

        // Store the fetched data in Redux store
        const allCurrencyData = getImmutableData(data);
        yield put(Actions.setAllCurrency({ allCurrencyData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllTaxrate() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch sale invoices
        const { data } = yield call(api.get, {
            route: '/Settings/all-taxratedrp',
        });

        // Store the fetched data in Redux store
        const allTaxrateData = getImmutableData(data);
        yield put(Actions.setTaxrate({ allTaxrateData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllLanguage() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch language data
        const { data } = yield call(api.get, {
            route: '/Settings/all-languagesdrp',
        });

        // Store the fetched data in Redux store
        const allLanguageData = getImmutableData(data);
        yield put(Actions.setLanguage({ allLanguageData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllOpttype() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch opttype data
        const { data } = yield call(api.get, {
            route: '/Settings/all-otptypesdrp',
        });

        // Store the fetched data in Redux store
        const allOpttypeData = getImmutableData(data);
        yield put(Actions.setOpttype({ allOpttypeData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllDateformate() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch dateformate data
        const { data } = yield call(api.get, {
            route: '/Settings/all-dateformatesdrp',
        });

        // Store the fetched data in Redux store
        const allDateformateData = getImmutableData(data);
        yield put(Actions.setDateformate({ allDateformateData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllTimezone() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch timezone data
        const { data } = yield call(api.get, {
            route: '/Settings/all-timezonesdrp',
        });

        // Store the fetched data in Redux store
        const allTimezoneData = getImmutableData(data);
        yield put(Actions.setTimezone({ allTimezoneData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllTimeformate() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch timeformate data
        const { data } = yield call(api.get, {
            route: '/Settings/all-timeformatdrp',
        });

        // Store the fetched data in Redux store
        const allTimeformateData = getImmutableData(data);
        yield put(Actions.setTimeformate({ allTimeformateData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllcustominputtype() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch timeformate data
        const { data } = yield call(api.get, {
            route: '/Settings/all-custominputtypesdrp',
        });

        // Store the fetched data in Redux store
        const allcustominputtypeData = getImmutableData(data);
        yield put(Actions.setAllcustominputtype({ allcustominputtypeData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllconnectiontype() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch timeformate data
        const { data } = yield call(api.get, {
            route: '/Settings/all-connectiontypesdrp',
        });

        // Store the fetched data in Redux store
        const allconnectiontypeData = getImmutableData(data);
        yield put(Actions.setAllconnectiontype({ allconnectiontypeData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllexpenstypes() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch timeformate data
        const { data } = yield call(api.get, {
            route: '/Stock/all-expenstypesdrp',
        });

        // Store the fetched data in Redux store
        const allExpensetypeData = getImmutableData(data);
        yield put(Actions.setAllExpensetype({ allExpensetypeData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllcustomfieldfor() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch timeformate data
        const { data } = yield call(api.get, {
            route: '/Settings/all-customfieldfordrp',
        });

        // Store the fetched data in Redux store
        const allcustomfieldforData = getImmutableData(data);
        yield put(Actions.setAllcustomfieldfor({ allcustomfieldforData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* uploadReportLogoImage({ logoImage }) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        const file = logoImage.originFileObj;
        const formData = new FormData();
        formData.append('file', file); // Use 'file' instead of 'File'

        const { data } = yield call(api.UploadImage, {
            route: `/Storage/upload`,
            data: formData,
        });
        const uploadReportLogoImageUrl = getImmutableData(data);
        yield put(Actions.setReportLogoImage({ uploadReportLogoImageUrl }));
        yield put(Actions.setLoader({ showLoader: false }));
        notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
    } catch (error) {
        handleNotification(error);
        console.log(error);
    }
}

function* fetchAllPurchaseReceiveDrp() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch timeformate data
        const { data } = yield call(api.get, {
            route: '/Purchases/all-purchasesrecdrp',
        });

        // Store the fetched data in Redux store
        const allPurchaseReceiveData = getImmutableData(data);
        yield put(Actions.setAllPurchaseReceiveDrp({ allPurchaseReceiveData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}
function* getAllPurchaseVouchers() {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch timeformate data
        const { data } = yield call(api.get, {
            route: '/Purchases/all-purchasevoucharsdrp',
        });
        // Store the fetched data in Redux store
        const allPurchaseVouchersData = getImmutableData(data);
        yield put(Actions.setAllPurchaseVouchers({ allPurchaseVouchersData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

const getImmutableData = (data) => {
    const immutableJson = Immutable.fromJS(data);
    return immutableJson.get ? immutableJson.get('data') : immutableJson;
};

function handleNotification(message) {
    if (message?.details?.response?.data?.errors.length > 0) {
        message.details.response.data.errors.map((value) => (
            notification('error', value)
        ));
    }
    else {
        notification('error', 'SERVE-POS API Issue.')
    }
}
export default function* rootSaga() {
    yield all([
        // For language
        takeLatest(ActionTypes.FETCH_LANGUAGE_DRP, fetchAllLanguage),
        takeLatest(ActionTypes.GET_ALL_PURCHASE_RECEIVE_DRP, fetchAllPurchaseReceiveDrp),
        takeLatest(ActionTypes.GET_ALL_POS_PRODUCT_TYPES, getAllPOSProductTypes),
        takeLatest(ActionTypes.FETCH_CUSTOMINPUTTYPE_DRP, fetchAllcustominputtype),
        takeLatest(ActionTypes.FETCH_CONNECTION_TYPE_DRP, fetchAllconnectiontype),
        takeLatest(ActionTypes.FETCH_EXPENSETYPES_DRP, fetchAllexpenstypes),
        // For opttype
        takeLatest(ActionTypes.FETCH_OPTTYPE_DRP, fetchAllOpttype),

        // For dateformate
        takeLatest(ActionTypes.FETCH_DATEFORMATE_DRP, fetchAllDateformate),

        // For timezone
        takeLatest(ActionTypes.FETCH_TIMEZONE_DRP, fetchAllTimezone),

        // For timeformate
        takeLatest(ActionTypes.FETCH_TIMEFORMATE_DRP, fetchAllTimeformate),
        takeLatest(ActionTypes.FETCH_CUSTOMFIELD_FOR_DRP, fetchAllcustomfieldfor),
        takeLatest(ActionTypes.FETCH_BANK_DRP, fetchAllBanks),
        takeLatest(ActionTypes.FETCH_CURRENCY_DRP, fetchAllcurrency),
        takeLatest(ActionTypes.FETCH_TAXRATE_DRP, fetchAllTaxrate),
        takeLatest(ActionTypes.UPLOAD_PROFILE_IMAGE, uploadProfileImage),
        takeLatest(ActionTypes.GET_ALL_STORES, getAllStores),
        takeLatest(ActionTypes.GET_ALL_WAREHOUSES, getAllWarehouses),
        takeLatest(ActionTypes.GET_ALL_CATEGORY, getAllCategory),
        takeLatest(ActionTypes.GET_ALL_SUBCATEGORY, getAllSubCategory),
        takeLatest(ActionTypes.GET_ALL_BRANDS, getAllBrands),
        takeLatest(ActionTypes.GET_ALL_UNITS, getAllUnits),
        takeLatest(ActionTypes.GET_ALL_USERS, getAllUsers),
        takeLatest(ActionTypes.GET_ALL_EXPENSE_CATEGORY, getAllExpenseCategory),
        takeLatest(ActionTypes.GET_ALL_VARIANTS, getAllVariants),
        takeLatest(ActionTypes.GET_ALL_SELLING_TYPES, getAllSellingTypes),
        takeLatest(ActionTypes.GET_ALL_BAR_CODE_SYMBOLOGIES, getAllbarcodesymbologies),
        takeLatest(ActionTypes.GET_ALL_TAX_TYPES, getAllTaxTypes),
        takeLatest(ActionTypes.GET_ALL_DISSCOUNT_TYPES, getAllDisscountTypes),
        takeLatest(ActionTypes.GET_ALL_CITY, getAllCities),
        takeLatest(ActionTypes.GET_ALL_STATE, getAllStates),
        takeLatest(ActionTypes.GET_ALL_COUNTRY, getAllCountries),
        takeLatest(ActionTypes.GET_ALL_CUSTOMER, getAllCustomer),
        takeLatest(ActionTypes.GET_ALL_QUOTATION, GetAllQuotation),
        takeLatest(ActionTypes.GET_ALL_SUPPLIER, getAllSuppliers),
        takeLatest(ActionTypes.GET_ALL_PRODUCTS_BY_SEARCH, getAllProductsBySearch),
        takeLatest(ActionTypes.FETCH_PRODUCT_DRP, fetchAllProducts),
        takeLatest(ActionTypes.GET_ALL_EMPLOYEE_DRP, GetAllEmployee),
        takeLatest(ActionTypes.GET_ALL_DESIGNATION_DRP, GetAllDesignation),
        takeLatest(ActionTypes.GET_ALL_DEPARTMENT_DRP, GetAllDepartment),
        takeLatest(ActionTypes.GET_ALL_GENDER_DRP, GetAllGender),
        takeLatest(ActionTypes.GET_ALL_WEEKOFFS_DRP, GetAllWeekoffs),
        takeLatest(ActionTypes.GET_ALL_NATIONALITIES_DRP, GetAllNationalities),
        takeLatest(ActionTypes.GET_ALL_BLOOD_GROUP_DRP, GetAllBloodGroup),
        takeLatest(ActionTypes.GET_ALL_SHIFTS_DRP, GetAllShift),
        takeLatest(ActionTypes.GET_ALL_STATES_BY_COUNTRY_ID, getAllStatesByCountryId),
        takeLatest(ActionTypes.GET_ALL_CITIES_BY_STATE_ID, getAllCitiesByStateId),
        takeLatest(ActionTypes.GET_ALL_LEAVE_TYPE_DRP, getAllLeaveTypes),
        takeLatest(ActionTypes.GET_ALL_SALE_QUOTATION, GetAllSaleQuotation),
        takeLatest(ActionTypes.GET_ALL_SALE_ORDER_DRP, getAllSaleOrder),
        takeLatest(ActionTypes.GET_ALL_PURCHASE_INVOICES, getAllInvoicesData),
        takeLatest(ActionTypes.FETCH_SHIPMENT_DRP, fetchAllShipments),
        takeLatest(ActionTypes.FETCH_SALE_INVOICE_DRP, fetchAllSaleInvoices),
        takeLatest(ActionTypes.UPLOAD_LOGO_IMAGE, uploadReportLogoImage),
        takeLatest(ActionTypes.GET_ALL_FUNCTYPES_DRP, getAllFunctionTypes),
        takeLatest(ActionTypes.GET_ALL_PURCHASE_VOUCHERS, getAllPurchaseVouchers),
        // Assuming setLoader is a saga function
    ]);
}