import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs } from 'antd';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import { PlusCircle } from "react-feather";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Flex, message, Upload, Image } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'react-bootstrap';
import commonActions from '../../../../core/Services/common/actions'
import * as commonSelectors from '../../../commonSelectors'
import axios from 'axios';

const EmployeeInformationTab = (props) => {
    const { form, currentModalData, handleCustomFieldsData, isUpdate, optionsData, fileList, setFileList } = props;
    const { genders, nationalities, shifts, departments, designations, bloodGroups } = optionsData;
    const dispatch = useDispatch();

    const [dob, setDob] = useState(null)
    const [joiningDate, setJoiningDate] = useState(null)

    
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [isError, setisError] = useState(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const profileImageData = useSelector(state => commonSelectors.setUploadProfileImage(state));
    const profileImage = profileImageData ? profileImageData.toJS() : [];

    const handleDobChange = (date) => {
        setDob(date);
        form.setFieldsValue({ dob: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const validateDob = (_, value) => {
        if (value && moment().diff(value, 'years') < 18) {
            return Promise.reject(new Error('Employee must be at least 18 years old'));
        }
        return Promise.resolve();
    };

    const handleJoiningDateChange = (date) => {
        setJoiningDate(date);
        form.setFieldsValue({ joiningDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    useEffect(() => {
        if (isUpdate) {
            uploadFromURL(currentModalData.formData?.profileUrl)
        }
    }, [currentModalData]);

    const uploadFromURL = async (url) => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
            const newFileList = [
                ...fileList,
                {
                    uid: `-${fileList.length}`,
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                },
            ];
            console.log(file);
            setFileList(newFileList);

            if (!newFileList.url || !newFileList.preview) {
                file.preview = await getBase64(newFileList);
            }
            setPreviewImage(file.url || file.preview);
        } catch (error) {
            console.error('Error downloading the image', error);
        }
    };

    useEffect(() => {
        if (currentModalData && currentModalData.formData && currentModalData.formData.id) {
            dispatch(commonActions.getAllStatesByCountryId({ stateParams: currentModalData.formData?.countryId }))
            dispatch(commonActions.getAllCitiesByStateId({ cityParams: currentModalData.formData?.stateId  }))
        }
    }, [currentModalData])

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    firstName: currentModalData.formData?.firstName,
                    lastName: currentModalData.formData?.lastName,
                    email: currentModalData.formData?.email,
                    contactNumber: currentModalData.formData?.contactNumber,
                    empcode: currentModalData.formData?.id,
                    dob: currentModalData.formData?.dob ? moment(currentModalData.formData.dob).format('YYYY-MM-DD') : null,
                    genderId: currentModalData.formData?.genderId,
                    nationalityId: currentModalData.formData?.nationalityId,
                    joiningDate: currentModalData.formData?.joiningDate ? moment(currentModalData.formData.joiningDate).format('YYYY-MM-DD') : null,
                    shiftId: currentModalData.formData?.shiftId,
                    departmentId: currentModalData.formData?.departmentId,
                    designationId: currentModalData.formData?.designationId,
                    bloodGroupId: currentModalData.formData?.bloodGroupId && currentModalData.formData?.bloodGroupId != 0 ? currentModalData.formData?.bloodGroupId : null,
                }}
                name="forForm" className='productActions_addModalWrapper'>
                <div className="row" style={{ marginBottom: '20px' }}>
                    <div className="col-lg-6">
                        <div className="input-blocks">
                            <Form.Item
                                name="profileUrl"
                                className="actions_addModalFormItem"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            return isError
                                                ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                                                : Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                >
                                    {fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                                {previewImage && (
                                    <Image
                                        wrapperStyle={{
                                            display: 'none',
                                        }}
                                        preview={{
                                            visible: previewOpen,
                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                        }}
                                        src={previewImage}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="firstName"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.first.name"
                                        popoverkey="hrm.employee.form.label.popover.first.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.first.name" />,
                                    },
                                    {
                                      type: 'string',
                                      message: 'The input must be a valid string.',
                                    },
                                    {
                                      pattern: /^[A-Za-z\s]+$/,
                                      message: 'First name should only contain alphabets.',
                                    },
                                    {
                                      min: 1,
                                      message: 'First name must be at least 1 characters.',
                                    },
                                    {
                                      max: 50,
                                      message: 'First name cannot exceed 50 characters.',
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="lastName"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.last.name"
                                        popoverkey="hrm.employee.form.label.popover.last.name"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.last.name" />,
                                    },
                                    {
                                      type: 'string',
                                      message: 'The input must be a valid string.',
                                    },
                                    {
                                      pattern: /^[A-Za-z\s]+$/,
                                      message: 'Last name should only contain alphabets.',
                                    },
                                    {
                                      min: 1,
                                      message: 'Last name must be at least 1 characters.',
                                    },
                                    {
                                      max: 50,
                                      message: 'Last name cannot exceed 50 characters.',
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="email"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.popover.email"
                                        popoverkey="hrm.employee.form.label.popover.email"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.email" />,
                                    },
                                    {
                                      type: 'email',
                                      message: "Please enter a valid email address.",
                                    },
                                    {
                                      min: 1,
                                      message: 'Email must be at least 1 characters.',
                                    },
                                    {
                                      max: 255,
                                      message: 'Email cannot exceed 255 characters.',
                                    },
                                ]}
                            >
                                <input type="email" />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="contactNumber"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.contact.number"
                                        popoverkey="hrm.employee.form.label.popover.contact.number"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.contact.number" />,
                                    },
                                    {
                                      pattern: /^\d+$/,
                                      message: "Contact number must contain only digits.",
                                    },
                                    {
                                      min: 1,
                                      message: "Contact number must be at least 1 digits.",
                                    },
                                    {
                                      max: 20,
                                      message: "Contact number cannot exceed 20 digits.",
                                    },
                                ]}
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="empcode"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.emp.code"
                                        popoverkey="hrm.employee.form.label.popover.emp.code"
                                    />
                                }
                            >
                                <input type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="dob"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.dob"
                                        popoverkey="hrm.employee.form.label.popover.dob"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.dob" />,
                                    },
                                    {
                                        validator: validateDob,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={dob}
                                    onChange={handleDobChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="genderId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.gender"
                                        popoverkey="hrm.employee.form.label.popover.gender"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.gender" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={genders.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="nationalityId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.nationality"
                                        popoverkey="hrm.employee.form.label.popover.nationality"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.nationality" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={nationalities.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    optionFilterProp="label"
                                    showSearch
                                    filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="joiningDate"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.joining.date"
                                        popoverkey="hrm.employee.form.label.popover.joining.date"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.joining.date" />,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={joiningDate}
                                    onChange={handleJoiningDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="shiftId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.shift"
                                        popoverkey="hrm.employee.form.label.popover.shift"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.shift" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={shifts.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="departmentId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.department"
                                        popoverkey="hrm.employee.form.label.popover.department"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.department" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={departments.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="designationId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.designation"
                                        popoverkey="hrm.employee.form.label.popover.designation"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="hrm.employee.form.validation.designation" />,
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={designations.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input-blocks">
                            <Form.Item
                                name="bloodGroupId"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="hrm.employee.form.label.blood.group"
                                        popoverkey="hrm.employee.form.label.popover.blood.group"
                                    />
                                }
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={bloodGroups.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default EmployeeInformationTab;
