import React, { useState, useEffect } from 'react';
import saleInvoiceAction from '../../../core/Services/sales/saleInvoice/actions.js';
import commonActions from '../../../core/Services/common/actions.js';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Select } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import TextEditor from "../../../feature-module/inventory/texteditor";
import * as commonSelectors from '../../commonSelectors';
import DatePicker from "react-datepicker";
import moment from 'moment';

const AddSaleInvoiceModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, saleInvoiceParams } = props;
    const [selectedDate, setSelectedDate] = useState(new Date);
    const [billDueDate, setBillDueDate] = useState(new Date);
    const [editorData, setEditorData] = useState();
    const ShipmentData = useSelector(state => commonSelectors.setShipments(state));
    const ShipmentdataSource = ShipmentData ? ShipmentData.toJS() : [];

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isUpdate) {
            setSelectedDate(currentModalData.formData.billDate ?  new Date(currentModalData.formData.billDate) : null);
            setBillDueDate(currentModalData.formData.billDueDate ? new Date(currentModalData.formData.billDueDate) : null);
            let editorFetchedData = currentModalData.formData?.notesHtml.replace(/""/g, '');
            setEditorData(editorFetchedData);
        }
    }, [currentModalData])

    useEffect(() => {
        dispatch(commonActions.fetchAllShipments());
    }, [dispatch])

    const handleDateChange = (date) => {
        setSelectedDate(date);
        form.setFieldsValue({ billDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handleBillDueDateChange = (date) => {
        setBillDueDate(date);
        form.setFieldsValue({ billDueDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        let stringifyData = `${data}`
        setEditorData(stringifyData);
    };

    const handleRecord = (actionName, saleInvoiceData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            saleInvoiceData.id = currentModalData.formData.id;
            saleInvoiceData.companyId = currentModalData.formData.companyId;
            saleInvoiceData.notesHtml = editorData;
        } else {
            saleInvoiceData.companyId = 0;
            saleInvoiceData.billDate = selectedDate;
            saleInvoiceData.billDueDate = billDueDate;
            saleInvoiceData.notesHtml = editorData;
        }

        dispatch(saleInvoiceAction.addUpdateSaleInvoice({ saleInvoiceData, actionName, saleInvoiceParams: saleInvoiceParams }));
        toggleModal();
    };

    return (

        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="sale.invoice.form.modal.title.update" />
                ) : (
                    <IntlMessages id="sale.invoice.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="sale.shipment.form.modal.update" />
                ) : (
                    <IntlMessages id="sale.shipment.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="sale.shipment.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="categoryForm"
                className='categoryActions_addModalWrapper'
                initialValues={{
                    companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
                    shipmentId: currentModalData.formData.shipmentId,
                    billDate: currentModalData.formData ? currentModalData.formData.billDate : null,
                    billDueDate: currentModalData.formData ? currentModalData.formData.billDueDate : null,
                }}
            >

                <div className='row'>

                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="shipmentId"
                                className='actions_addModalFormItem'
                                label={
                                    <CustomFormLabel
                                        labelkey="sale.invoice.form.sale.order.label.shipment"
                                        popoverkey="sale.invoice.form.label.popover.sale.invoice.shipment"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="sale.invoice.modal.validation.sale.invoice.shipment" />,
                                    },
                                ]}
                            >
                                <Select
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    classNamePrefix="react-select"
                                    options={ShipmentdataSource.map(f => ({ label: f.name, value: f.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="input-blocks">
                                <Form.Item
                                    name="billDate"
                                    className="actions_addModalFormItem"
                                    label={
                                        <CustomFormLabel
                                            labelkey="sale.invoice.form.label.bill.date"
                                            popoverkey="sale.invoice.form.label.popover.bill.date"
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="sale.invoice.modal.validation.bill.date" />,
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Select Date"
                                        className="actions_addModalFormItem"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="input-blocks">
                                <Form.Item
                                    name="billDueDate"
                                    className="actions_addModalFormItem"
                                    label={
                                        <CustomFormLabel
                                            labelkey="sale.invoice.form.label.bill.due.date"
                                            popoverkey="sale.invoice.form.label.popover.bill.due.date"
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="sale.invoice.modal.validation.bill.due.date" />,
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        selected={billDueDate}
                                        onChange={handleBillDueDateChange}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Select Due Date"
                                        className="actions_addModalFormItem"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="input-blocks summer-description-box">
                            <Form.Item
                                name="notesHtml"
                                className="actions_addModalFormItem"
                                label={
                                    <CustomFormLabel
                                        labelkey="sale.invoice.modal.description"
                                        popoverkey="sale.invoice.form.shipment.label.description"
                                    />
                                }
                            >
                                <TextEditor data={editorData} onChange={handleEditorChange} />
                            </Form.Item>

                        </div>
                    </div>
                </div>
            </Form >

        </Modal >

    )
}

export default AddSaleInvoiceModal
