export const denominationLoader = state => {
    return state.services.denominations.getIn(['showLoader']);
 };
 export const setDenomination = state => {
    return state.services.denominations.getIn(['allDenominationData']);
 };
 export const setDenominationById = state => {
    return state.services.denominations.getIn(['denominationData']);
 };
export const todayExpensesLoader = state => {
    return state.services.todayexpenses.getIn(['showLoader']);
 };
 export const setTodayExpense = state => {
    return state.services.todayexpenses.getIn(['allTodayExpensesData']);
 };
 export const setTodayExpensesById = state => {
    return state.services.todayexpenses.getIn(['todayExpensesData']);
 };

export const AdvanceBookingsLoader = state => {
    return state.services.advanceBookings.getIn(['showLoader']);
 };
 export const setAdvanceBookings = state => {
    return state.services.advanceBookings.getIn(['allAdvanceBookingsData']);
 };
 export const setAdvanceBookingsById = state => {
    return state.services.advanceBookings.getIn(['advanceBookingData']);
 };

export const BookOrderLoader = state => {
    return state.services.advanceBookings.getIn(['showLoader']);
 };
 export const setBookOrder = state => {
    return state.services.advanceBookings.getIn(['allBookOrderData']);
 };
 export const setBookOrderById = state => {
    return state.services.advanceBookings.getIn(['advanceBookingData']);
 };

 export const setPosOthersCategoriesLoader = state => {
   return state.services.advanceBookings.getIn(['showLoader']);
};

 export const setposotherscategories = state => {
   return state.services.pointofsale.getIn(['allCategoryData']);
};
export const setposothersproductsbycategory = state => {
   return state.services.pointofsale.getIn(['allsubcategoryData']);
};
